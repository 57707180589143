.pricing-parent-div {
    position: relative;
    min-height: 80vh;
}
.pricing-container {
    display: block;
    width: 96%;
    color: white;
    padding: 80px 0 140px;
    margin: auto;

    .pricing-card {
        position: relative;
        display: block;
        border-radius: 24px;
        border: 1px solid #ffffff1a;
        background: #ffffff0d;
        width: 100%;
        max-width: 800px;
        padding: 40px;
        margin: 0 auto;
        backdrop-filter: blur(5px);

        .card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;

            .title {
                font-size: 34px;
                font-weight: 700;
            }

            .account-type {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                span {
                    color: #10994D;
                    font-weight: bold;
                    margin-left: 10px;
                }
            }

            .toggle-switch {
                button {
                    position: relative;
                    background-color: transparent;
                    color: white;
                    font-size: 14px;
                    border-radius: 10px;
                    cursor: pointer;
                    outline: none;
                    padding: 0;
                    border: 1px solid #ffffff1a;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    span {
                        padding: 8px 0;
                        border-radius: 10px;
                        width: 50%;
                        min-width: 90px;
                        text-align: center;
                        z-index: 1;
                        transition: all 0.3s ease-in-out;
                        -webkit-transition: all 0.3s ease-in-out;

                        &.active {
                            background-color: #10994dff;
                            position: absolute;
                            top: 0;
                            bottom:0;
                            left: auto;
                            right: 50%;
                            height: 100%;
                            padding: 0;
                            z-index: 0;
                        }
                    }

                    &.first {
                        span.active {
                            left: 0;
                            right: 50%;
                        }
                    }

                    &.second {
                        span.active {
                            left: 50%;
                            right: 0;
                        }
                    }
                }

                &.toggle-one {
                    button{
                        padding: 0;
                        border: none;
                    }
                }

                &.toggle-thirdly {
                    button {
                        span {
                            width: 33.33%;
                        }

                        &.first {
                            span.active {
                                left: 0;
                                right: 66.66%;
                            }
                        }
    
                        &.second {
                            span.active {
                                left: 33.33%;
                                right: 33.33%;
                            }
                        }
    
                        &.third {
                            span.active {
                                left: 66.66%;
                                right: 0;
                            }
                        }
                    }

                }
            }
        }

        .description {
            color: #848484ff;
            margin: 30px 0 20px;
            line-height: 1.5;
            font-size: 18px;
            height: 72px;
            display: flex;
            align-items: center;
        }

        .old-price-section {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            .old-price {
                font-size: 20px;
                color: #848484ff;
                text-decoration: line-through;
            }

            .old-price-procent {
                font-size: 12px;
                border-radius: 20px;
                padding: 2px 16px;
                background: #10994D;
                color: white;

                &.opp_alternate {
                    background: rgba(255, 255, 255, 0.2);
                }
            }
        }

        .price-section {
            display: flex;
            align-items: baseline;
            position: relative;

            .price {
                font-size: 52px;
                font-weight: bold;
            }

            .term {
                margin-left: 5px;
                font-size: 18px;
                color: #848484ff;

                &.old-term {
                    text-decoration: line-through;
                }
            }

            .price-gift-badge {
                position: absolute;
                right: 0;
                top: -15px;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
                cursor: pointer;

                .pgb-text {
                    font-size: 12px;
                
                    .pgbt-color {
                        font-weight: bold;
                        color: #10994D;
                    }
                }

                .pgb-icon {
                    img {
                        width: auto;
                        height: 90px;
                    }
                }
            }
        }

        .features-title {
            margin-bottom: 0;
            text-align: left;
            font-size: 17px;
        }

        .features-list {
            display: flex;
            justify-content: space-between;

            ul {
                padding: 0;
                list-style: none;
                width: 48%;

                li {
                    background: url('../assets/check-icon.svg') no-repeat;
                    background-size: 24px;
                    background-position: 0 8px;
                    padding: 10px 20px 10px 40px;
                    text-align: left;
                    font-size: 16px;
                    height: 30px;

                    &.bold {
                        font-weight: bold;
                    }

                    span {
                        color: #10994dff;
                        font-weight: bold;
                    }
                }
            }
        }

        .subscribe-button {
            background-color: #2603fbff;
            color: white;
            border: none;
            border-radius: 4px;
            padding: 20px;
            font-size: 20px;
            font-weight: bold;
            width: 100%;
            cursor: pointer;
            margin-top: 20px;
            z-index: 1;

            &.deactive_sb {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }

        .subscribe-button-container {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            .decrement-button, .increment-button {
                position: absolute;
                left: 15px;
                top: 50%;
                transform: translateY(-50%);
                margin-top: 10px;
                border: none;
                border-radius: 100%;
                width: 46px;
                height: 46px;
                padding: 0;
                cursor: pointer;
                outline: none;
                z-index: 2;
                transition: all 0.3s ease-in-out;
                -webkit-transition: all 0.3s ease-in-out;

                &.increment-button {
                    left: auto;
                    right: 15px;
                }

                &:hover {
                    transform: translateY(-50%) scale(1.1);
                    box-shadow: 0px 0px 10px 0px #00000080;
                }

                &:active {
                    transform: translateY(-50%) scale(1);
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .extra-info {
            margin-top: 20px;
            font-size: 14px;
            color: #848484ff;
            text-align: center;
        }
    }
}