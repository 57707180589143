@include for-tablet {
    .pricing-container .pricing-card {
        width: 880px;
        transform: scale(0.85) translateX(calc(50% - 880px));
        -webkit-transform: scale(0.85) translateX(calc(50% - 880px));
        left: 50%;
        transform-origin: top left;
        -webkit-transform-origin: top left;
    }
}

@include for-mobile {
    .pricing-container {
        padding: 60px 0 80px;

        .pricing-card {
            width: 880px;
            transform: scale(0.4) translateX(calc(50% - 880px));
            -webkit-transform: scale(0.4) translateX(calc(50% - 880px));
            left: 50%;
            transform-origin: top left;
            -webkit-transform-origin: top left;
            margin-bottom: -520px;
    
            .card-header {
                
                .title {
                    font-size: 38px;
                }

                .account-type {
                    font-size: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;

                    span {
                        font-size: 24px;
                    }
                }

                .toggle-switch button {
                    font-size: 24px;
                    span {
                        min-width: 140px;
                    }
                }
            }

            .description {
                font-size: 24px;
                height: 140px;
            }

            .old-price-section {
                .old-price {
                    font-size: 40px;
                }
    
                .old-price-procent {
                    font-size: 16px;
                    padding: 2px 18px;
                }
            }

            .price-section {
                .price {
                    font-size: 80px;
                }
                .term {
                    font-size: 28px;
                }
                .price-gift-badge {
                    .pgb-text {
                        font-size: 20px;
                        .pgbt-color {
                            display: block;
                        }
                    }
                    .pgb-icon {
                        img {
                            height: 100px;
                        }
                    }
                }
            }

            .features-title {
                font-size: 26px;
            }

            .features-list ul li {
                font-size: 20px;
                height: 54px;
                padding: 10px 10px 10px 40px;
            }

            .subscribe-button {
                font-size: 34px;
                padding: 34px;
            }

            .subscribe-button-container {
                .decrement-button, .increment-button {
                    width: 80px;
                    height: 80px;

                    &:hover {
                        transform: translateY(-50%) scale(1);
                        box-shadow: 0px 0px 10px 0px #00000080;
                    }
    
                    &:active {
                        transform: translateY(-50%) scale(0.9);
                    }
                }
            }

            .extra-info {
                font-size: 24px;
            }
        }
    }
}