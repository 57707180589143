.navbar {
    background-color: transparent;
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    z-index: 1000;
}

.navbar-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    padding: 10px 0;
    height: 50px;
    width: auto;
    z-index: 10001;

    img {
        height: 100%;
        width: auto;
        object-fit: contain;
    }
}

.navigation {
    display: flex;
    align-items: center;
    gap: 20px;
    z-index: 10000;
}

.nav-link {
    color: white;
    text-decoration: none;
    padding: 10px 15px;
    &.only-mobile {
        display: none;
    }
}

.open-suite {
    background-color: white;
    color: black;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
}

.open-suite:hover {
    background-color: whitesmoke;
}

.hamburger {
    display: none;
    width: 10px;
    height: 20px;
    padding: 0 10px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    z-index: 10001;

    span {
        display: block;
        position: absolute;
        height: 4px;
        width: 100%;
        background: white;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;

        &:nth-child(1) {
            top: 0px;
        }

        &:nth-child(2),
        &:nth-child(3) {
            top: 8px;
        }

        &:nth-child(4) {
            top: 16px;
        }

    }

    &.active {
        span {
            &:nth-child(1) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
            &:nth-child(2) {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            &:nth-child(3) {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
            &:nth-child(4) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
        }
    }
}
.socials, .navbar-text {
    display: none;
}
.navbar-text {
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
    width: calc(100% - 40px);
    padding: 20px 20px;
    opacity: .6;
    text-align: center;
    font-size: 75%;
    .navbar-text-container {
        width: 100%;
    }
}

.header-info-bar {
    background-image: linear-gradient(90deg, #FF1CF7 0%, #2603FB 100%);
    color: white;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .header-info-bar-text {
        padding: 0 15px;
    }
}

@include for-tablet {
    .navbar {
        padding: 0 10px;
        &.active {
            position: fixed;
            width: calc(100vw - 20px);
            background-color: black;
            top: 0;
        }
    }

    .logo {
        height: 30px;
    }

    .navbar-container {
        justify-content: space-between;
    }

    .navigation {
        opacity: 0;
        visibility: hidden;
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        top: 50px;
        left: 0;
        right: 0;
        width: calc(100vw - 20px);
        height: calc(100vh - 70px);
        background-color: black;
        padding: 10px 10px;
        gap: 5px;
        transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transition: .3s all ease-in-out;
        -webkit-transition: .3s all ease-in-out;
        overflow: auto;
        &.active {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
            -webkit-transform: translateX(0);
        }
    }

    .nav-link {
        font-size: 24px;
        line-height: 1;
        padding: 5px 15px;
        opacity: .6;
        &.only-mobile {
            display: block;
        }
        &.active {
            opacity: 1;
        }
        &-alternate {
            font-size: 80%;
            opacity: .6 !important;
        }
        &.mt-5 {
            margin-top: 5vh;
        }
    }

    .open-suite {
        display: none;
    }

    .hamburger {
        display: block;
    }

    .socials {
        display: flex;
        position: relative;
        width: 142px;
        left: 0;
        margin: 30px 0;
    }

    .navbar-text.active {
        display: flex;
    }

}

@include for-mobile {
    .navbar {
        padding: 0 10px;
        &.active {
            position: fixed;
            width: calc(100vw - 20px);
            background-color: black;
            top: 0;
        }
    }

    .logo {
        height: 30px;
    }

    .navbar-container {
        justify-content: space-between;
    }

    .navigation {
        opacity: 0;
        visibility: hidden;
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: nowrap;
        top: 50px;
        left: 0;
        right: 0;
        width: calc(100vw - 20px);
        height: calc(100vh - 70px);
        background-color: black;
        padding: 10px 10px;
        gap: 5px;
        transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transition: .3s all ease-in-out;
        -webkit-transition: .3s all ease-in-out;
        overflow: auto;
        &.active {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
            -webkit-transform: translateX(0);
        }
    }

    .nav-link {
        font-size: 24px;
        line-height: 1;
        padding: 5px 15px;
        opacity: .6;
        &.only-mobile {
            display: block;
        }
        &.active {
            opacity: 1;
        }
        &-alternate {
            font-size: 80%;
            opacity: .6 !important;
        }
        &.mt-5 {
            margin-top: 5vh;
        }
    }

    .open-suite {
        display: none;
    }

    .hamburger {
        display: block;
    }

    .socials {
        display: flex;
        position: relative;
        width: calc(100% - 40px);
        left: 15px;
        margin: 30px 0;
    }

    .navbar-text.active {
        display: flex;
    }

    .header-info-bar {
        font-size: 14px;
    }

}