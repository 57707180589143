@include for-mini-desktop-1 {
    .node-9787052 {
        transform: scale(0.9) translateX(-52%);
        -webkit-transform: scale(0.9) translateX(-52%);
        left: 50%;
        transform-origin: top left;
        -webkit-transform-origin: top left;
    }

    .node-8749913 img {
        top: -220px;
    }

    .node-9787051 {
        width: 90%;
    }
}

@include for-mini-desktop-2 {
    .node-9787052 {
        transform: scale(0.85) translateX(-52%);
        -webkit-transform: scale(0.85) translateX(-52%);
        left: 50%;
        transform-origin: top left;
        -webkit-transform-origin: top left;
    }

    .node-8749913 img {
        top: -150px;
    }

    .node-9787051 {
        width: 90%;
    }
}

@include for-tablet {
    .node-9787052 {
        transform: scale(0.85) translateX(-52%);
        -webkit-transform: scale(0.85) translateX(-52%);
        left: 50%;
        transform-origin: top left;
        -webkit-transform-origin: top left;
    }

    .node-8749915 {
        left: -60px;
    }

    .node-8749838 {
        top: 175px;
    }

    .node-8749913 img {
        top: 0px;
    }

    .node-9787051 {
        width: 90%;
    }
}

@include for-mobile {
    .node-9787052 {
        transform: scale(0.3) translateX(-51%);
        -webkit-transform: scale(0.3) translateX(-51%);
        left: 50%;
        transform-origin: top left;
        -webkit-transform-origin: top left;
        width: 1150px;
        margin: 100px auto 0;
    }

    .node-9787051 {
        margin: -400px auto 0;
    }

    .node-8749931 {
        margin: 120px auto 120px;
    }

    .node-9787054 {
        flex-direction: column;
        gap: 80px;
    }
}