
.footer-section {
    position: relative;
    width: 100%;
    max-width: 1400px;
    height: 540px;
    margin: auto;
}

.footer-icon-bg {
    position: absolute;
    width: 334px;
    height: 334px;
    top: 30px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 0;
    border-radius: 50%;
    background: #2603fb;
    filter: blur(50px);
}

.footer-container {
    position: relative;
    width: 98%;
    max-width: 1150px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.footer-left {
    position: relative;
    width: 40%;
    z-index: 10;
    padding: 50px 50px;
}

.footer-left-heading {
    position: relative;
    width: 100%;
    text-align: left;
    color: white;
    margin: 0 auto 30px;
}

.footer-left-heading-0 {
    font-size: 15px;
    font-weight: 700;
    line-height: 29.200000762939453px;
    color: #ffffffff;
}

.footer-left-deesc {
    position: relative;
    width: 100%;
    margin-bottom: 90px;
}

.footer-left-deesc-0 {
    font-size: 17px;
    font-weight: 400;
    line-height: 18px;
    color: #6d6d6dff;
}

.footer-left-bottom {
    position: relative;
}

.footer-left-bottom-0 {
    font-size: 13px;
    font-weight: 400;
    line-height: 14px;
    color: #6d6d6dff;
}

.footer-right {
    position: relative;
    width: 40%;
    z-index: 10;
    padding: 50px 0px 50px 50px;
}

.footer-right-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 0 50px;
}

.footer-right-left {
    width: 50%;
}

.footer-right-right {
    width: 50%;
}

.footer-right-left-heading {
    position: relative;
    margin: 0 0 25px;
}

.footer-right-left-heading-0 {
    font-size: 15px;
    font-weight: 700;
    line-height: 29.200000762939453px;
    color: #ffffffff;
}

.footer-right-links {
    position: relative;
    margin: 10px 0;
}

.footer-right-links-0 {
    font-size: 31px;
    font-weight: 500;
    letter-spacing: -1px;
    line-height: 30.21428680419922px;
    color: #6d6d6dff;
}

.footer-right-right-heading {
    position: relative;
    margin: 0 0 50px;
}

.footer-right-icons {
    position: relative;
    margin: 20px 0;
}

.footer-right-bottom {
    position: relative;
    display: flex;
    gap: 50px;
}

.footer-right-extras {
    position: relative;
}

.footer-right-extras-0 {
    font-size: 15px;
    font-weight: 400;
    line-height: 29.200000762939453px;
    color: #6d6d6dff;
}

.footer-middle {
    position: absolute;
    width: 100%;
    height: 539px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 1;
}

.footer-middle-bg {
    position: absolute;
    width: 100%;
    height: 514px;
    top: 25px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    border-radius: 25px 25px 0px 0px;
    background: #000000fc;
    box-sizing: border-box;
    border-left: 1px solid #ffffff1a;
    border-right: 1px solid #ffffff1a;
    border-top: 1px solid #ffffff1a;
    border-bottom: 1px solid #ffffff1a;
    backdrop-filter: blur(25px);
}

.footer-middle-icon {
    position: absolute;
    width: 55px;
    height: 50px;
    top: 0px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 9;
}

.footer-middle-icon_325459 {
    position: absolute;
    width: 33.078453063964844px;
    height: 45.569488525390625px;
    top: 4.423828125px;
    left: 10.9661865234375px;
    opacity: 1;
    z-index: 1;
    overflow: hidden;
}

.footer-middle-icon_325453 {
    position: absolute;
    width: 55px;
    height: 35.21096420288086px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
}
.footer-middle-icon_325454 {
    position: absolute;
    width: 2.0766258239746094px;
    height: 17.713115692138672px;
    top: 17.494140625px;
    left: 2.101806640625px;
    opacity: 1;
    z-index: 0;
    overflow: hidden;
}

.footer-middle-icon_325455 {
    position: absolute;
    width: 1.0761024951934814px;
    height: 10.085390090942383px;
    top: 21.4013671875px;
    left: 0px;
    opacity: 1;
    z-index: 1;
    overflow: hidden;
}

.footer-middle-icon_325456 {
    position: absolute;
    width: 2.076627731323242px;
    height: 17.713115692138672px;
    top: 17.498046875px;
    left: 50.8251953125px;
    opacity: 1;
    z-index: 2;
    overflow: hidden;
}

.footer-middle-dots {
    position: absolute;
    width: 100%;
    height: calc(100% - 90px);
    top: 90px;
    left: 0px;
    z-index: 1;
    background: url('../assets/footer-dots.png') no-repeat center center;
}

.footer-logo {
    position: absolute;
    z-index: 100;
    bottom: -130px;
    left: 50px;

    img {
        height: 120px;
        width: auto;
        object-fit: contain;
    }
}

.footer-badge {
    position: absolute;
    z-index: 100;
    bottom: -105px;
    right: 80px;
}

.footer-icons_4241095 {
    position: absolute;
    width: 144.45523071289062px;
    height: 149.8518524169922px;
    top: 100px;
    left: 1153.19921875px;
    opacity: 1;
    z-index: 2;
}

.footer-icons_4241100 {
    position: absolute;
    width: 26.851852416992188px;
    height: 26.851852416992188px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
}

.footer-icons_4241100_119 {
    position: absolute;
    width: 26.851852416992188px;
    height: 21.817934036254883px;
    top: 2.5166015625px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    overflow: hidden;
}

.footer-icons_4241101 {
    position: absolute;
    width: 26.851852416992188px;
    height: 26.851852416992188px;
    top: 0px;
    left: 58.85185241699219px;
    opacity: 1;
    z-index: 1;
}

.footer-icons_4241101_121 {
    position: absolute;
    width: 26.83612060546875px;
    height: 26.846607208251953px;
    top: -0.000007346824531850871px;
    left: -0.00014467592700384557px;
    opacity: 1;
    z-index: 0;
}

.footer-icons_4241101_122 {
    position: absolute;
    width: 26.83612060546875px;
    height: 26.846607208251953px;
    top: 0px;
    left: 0.000244140625px;
    opacity: 1;
    z-index: 0;
    overflow: hidden;
}

.footer-icons_4241101_123 {
    position: absolute;
    width: 13.793041229248047px;
    height: 13.793041229248047px;
    top: 6.529296875px;
    left: 6.531005859375px;
    opacity: 1;
    z-index: 1;
    overflow: hidden;
}

.footer-icons_4241101_124 {
    position: absolute;
    width: 3.220123529434204px;
    height: 3.220128297805786px;
    top: 4.646484375px;
    left: 18.98486328125px;
    opacity: 1;
    z-index: 2;
    overflow: hidden;
}

.footer-icons_4241102 {
    position: absolute;
    width: 26.851852416992188px;
    height: 26.851852416992188px;
    top: 0px;
    left: 117.70370483398438px;
    opacity: 1;
    z-index: 2;
}

.footer-icons_4241102_126 {
    position: absolute;
    width: 26.851852416992188px;
    height: 26.851852416992188px;
    top: -0.000007064254532451741px;
    left: -0.0005787037080153823px;
    opacity: 1;
    z-index: 0;
}

.footer-icons_4241102_127 {
    position: absolute;
    width: 26.851852416992188px;
    height: 26.851852416992188px;
    top: 0px;
    left: 0.00048828125px;
    opacity: 1;
    z-index: 0;
    overflow: hidden;
}

.footer-middle-icon_325457 {
    position: absolute;
    width: 1.0761045217514038px;
    height: 10.085390090942383px;
    top: 21.4013671875px;
    left: 53.9239501953125px;
    opacity: 1;
    z-index: 3;
    overflow: hidden;
}

.footer-middle-icon_325458 {
    position: absolute;
    width: 43.990638732910156px;
    height: 35.207374572753906px;
    top: 0px;
    left: 5.517333984375px;
    opacity: 1;
    z-index: 4;
    overflow: hidden;
}

.footer-bg_4241113_325459 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 1;
    background-color: transparent;
    background-size: contain;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAuCAYAAACiR6CHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPzSURBVHgB7VjbVeMwEB3Z+d3E6cB0wHYAFQAVECoAKghUAFRAqGChArIVbLYCshU4Tj74AEt7r7B9EsfyIzk8PrjnQGxZGl2P5DszUtIC0fNzKC8vu2JMiNug43k9Y0yQPVdKzV61jnE5E8+byOvrtN/vT5vaV3UdosViryNyoLU+xGyhtMcUk4wTpe76P36MZRMyJOEbMzQie1IBeGZitL7xfH+I21CqJxsnSXLi8pZX1rhYLK48Yx7riLzNoGYwPsJE07qutAfST7A/bERmPp/famPO5B0B+xecR6rI0CNgP5APAOcpEsrJRPP54L09UkYoiuOzNTK4GMonwFNqGEVRkJOhV6TmS3hHBNCkgWRkfJFj+UT4Sh3wt8N/pZ+wMTOj1ISXiiqr1K5sCGoRJcDaEqGdYOV5Or/1DDbuzZoBEAm63X3+ac87ly0AUTzPbIHMpKTLKCcjWl+A/lQ+A5hXJ8llTgYKOkPscb69p/WtbAGo7i/XM63UZRYe8k8bDffikvTNAuQyAteDfrc7yq49+UL4JuPCNxkX2pOBMtdpkuFz9ntPMpR16NFOr9fbKVPtFKMAz9GnzzRTWqAZmdQTiCEPFEjbptS4rKtGn+w6MeZ3On7ZS7N2ZDC5SZKjfAKt92kEnQ+Qe4Rsc0X6rJ05CnKV43T8z5wgEnJh4GxKBm93k3tArDpPsSwPNnJ3OiEJuZJ1LOWeTZZ8n89DjluuBmzoEblrTKYMyDl61jBqn5TcXdWLQObv2QfeiRpOUU4GBk6z5SBSTxwWDNd+LXwB/BwWbHH5Tsv6dxx2QkTaR1QL9yxfSSRtP0RGz435L9sPJS9yjCRbWPrCM3YcayWMG7H8RdtAHIFzpaKM5/MnaZkLg+iYSRMma1b0rWLa63Z3spsvrMBpnvpRMIX8aYUM9OCvtDWYClvCT7/92NhJRsqTZSewGS/7vd4Fr/F7zXtph7E4yWh9L03BEKH1dWE875svtdZjcZGhmDUObsiLPc97zEpTqx+dzqNU5LsrwMtgvomTDNFq7REeUkKhJWJM40KPVUGxbf3T1nokDV1NjbEegqjxnA/3zZaZS5wkY6kjYwOZO1ex6QA3Kgqvvq02EX9SUiPcH6H9LdepSMAYKMuO0pxnenEc/1mrr9+Sq/3liO4Cl873/ds1VQZJJmdlY1SFsV3GJ2m6IRuCnmt1wEhwp2NgVcHPuKKKf1VfI5bvvOpcuP4cOI4veLokW2JZIDcmYwlF0QBLdiWbLBk3vFLnyzX1VmRSQlbkWh0CvG34k6K4bU0mJ8VlY9JdRYreYPpZsyxbk7GEmEYi4cbgY0XVVSrIjt0MFRzC2eTzL+I/m1AgqZi43aUAAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    background-position: center;
}

.footer-bg_4241113_325454 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 1;
    background-color: transparent;
    background-size: contain;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAATCAYAAABV/rckAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABCSURBVHgB1Y8xDQAgDAQ/KMABSEIKTghKm9KhG6UkDLXALZ+/7QCHmDsRZYjI4LVMVUvaZg2PhMA/xxNmFPDKevcAICUaReB8sRwAAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    background-position: center;
}

.footer-bg_4241113_325455 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 1;
    background-color: transparent;
    background-size: contain;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAALCAYAAABVuVzsAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAzSURBVHgBncaxEQAQEATAZ7xYCUqj/+D9RZLDmFGBjVYMaGZWggM7q9Yoz3/GTVpkn6Qfz9ESVmmEr40AAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    background-position: center;
}

.footer-bg_4241113_325456 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 1;
    background-color: transparent;
    background-size: contain;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAATCAYAAABV/rckAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABHSURBVHgB1c9BDQAhDETRgawAVglWkIAlFCCxDT1DKQkBDbxkDnP8IKJArWWYzxY8UEUkemxDNZ2zvHFU+SY4V1Ycj95/wxNY3hbZlEPAkAAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-position: center;
}

.footer-bg_4241113_325457 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 1;
    background-color: transparent;
    background-size: contain;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAALCAYAAABVuVzsAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAvSURBVHgBnYdBDQAgDMQKMzBLOMAZUg8DI0BAwfpo2iLJMesV8KNxgz+PfMwV0TZ/LQgjIglJEAAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-position: center;
}

.footer-bg_4241113_325458 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 1;
    background-color: transparent;
    background-size: contain;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAkCAYAAAAdFbNSAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAI+SURBVHgB7ZjtcdswDIYByr9z8gTVBnUnqDtB0wmaDZwNWk/QywRxN2g3cCaoNog6gb78MyIDMLRD0lb8cbHE5PTeSTIsingIQoQkhBOU53kMUXQ5QvyolEpom+gTiEnrRUpltC8Qkbf0Qak7aJp0PB5ncKTw0IYaVIirCPGrApjCa0mpVCLe0ACWhw5gLzTDjkajmVTqmsx42ydFkCJHHRXU5n9bPwLxAw02oQtiivSkBWbRNM18H/yL0HlVXQqAWwdWqUIC/AYpl2RxdAo4UnrWeLaE4P4/e2mVUf/z8cXFAo5VXde/yqpSm60s7/OyvDYOX1UUnCvu3/bH/o/qpKqqW7sDgv15Dlhf7Mf2yxwHXehEuCyJNZ9ChyJ/Ezvqe8FXq9XMSYc8T6AHsV8bnNPSPo92QxFF92tbNs0nuslS6EkcceL5Z8zC8GRsiHWjKIp+rH/T0jXvE5jF/pnDmDHxuWmip8NKCwhITpqY+0tH2oky4hwCEq3ZNxuD1nV94J1Vlgsqp38gJEm5AOYCXVW/62Ne11Pg8go6l+9OqXDnFPMQ119jxpwigkZiPwcsIUw9LwpCTDg9NpWOwj+DAOVxxcI7n3AphYBEFZoXicT+z4fWo+qrEvpiDvNI7EjsaLu9kPcksxRvPajtggblTUdfantDEvAGNUB3pQG6Kw3QXWmA7koDdFd6R9D0kRFCUAuH2NEwk1J+gwCkOZ4+xjtyoZ+Av5zydf4cYg7m8cHRvKUkxk5DextneYzZI2G+hUg8AvweAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: center;
}

.footer-bg_4241100_119 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 1;
    background-color: transparent;
    background-size: contain;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAXCAYAAAAYyi9XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAINSURBVHgBtVZLcoJAEB0+7jkCNwgL98ETRE9gsrMsFXIC9ASB0rLcJd7AG0h2ZhWPwBFYxn9eEzAwyi+SVwUz9HzedM8begR2I0zTVA6HQ/N0Oql4fEEQ1pPJxE3rL0SVTqejzmYzj5XAYDAwQDJEVUlMCtLtdtui+Xq9no7v9m63G9F3QNjv9x9RWDA2ipKCzArJ0uCFC1HQbwSvg74ivWDQUKi1Wm1JnrIcIIxqDhlBDQnf4KFnGMbLmZD9hiQgDT1OxX6/z2znQH2tzWbjxAl9bmWvIF12u13t2gxY8R0rCNrP+FbJoX2Bx+D66pIkfYLYRX0OJa6n0+malQSi4cR1cVYp1GRjNUbOeIoEkZLnCisA7HUjfkyCkIZ75qLRzRlPJDorSHYNcrgKrYB3fyOQ5cQ2RKJZsH8ACca2bf+CkGIML+esYhyPx3feFnlIrptVk0KhNm+Tospqtfqq1+ueKIofrIQK00ACxDFyeLsc/wBZE4XFKgC8e7pmFxPsskxnsfTh5gHvnLQkkCAkRcHL1o176WHhw7RGIa2B8hgKymVlQuzlpTgxazTI7lmFZASZrggUSsqDCAXlOQ1hfWA/v7BCIEVibGs8Hvt5fUmlSvjjbpMBJSsKEJHAnrPuMDzOs1MWRwrSMUkbpHrGGPJiQcIqQ3RByIOSL0KrgDy4jeE35eHxy160eHwD+KMERMnjEb0AAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    background-position: center;
}

.footer-bg_4241101_122 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 1;
    background-color: transparent;
    background-size: contain;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAG5SURBVHgB7VbbbcJAEFwf5jtOBbkOSDpwB2mBApBiKgipIEbig7+QCpIOoARTQUwHzifilRnrFixLKBGcUSJlJNu7d+edvYduJ5Aaer1e3Gq17ne7XYwnQpM+36FwT26MyZbL5XA8HufVAYEaSZJEm83mDWYs/jBB4v00TYs9GYgsiKYwrWaJWc2Q4WK73dIuYBfHIqLfVtxOEASxHFYjB+EdCUN6IHpUIvw4DMNwoNmcAia/Xq+7IC3jIia//cDN6sMRPY1Go4F4AvY/BeEDbczu2iCDRDthT8QjuEJqr1arrgFzhw73qH56zgW3AnEz2tjzjnHHW0CaSzOY88VDxJlFzllIgwCPNdI8cjVIZuVCuMTM/sn+ONnJF+6vJ2sali/cIMWeDM6NNADEveKXRZhkWTUD39AqDtJP3vpz1x5Th4hHsDCD5Na57wYFLtVOVOwX8QjEe1YbxXNWCp5q+WYG6OifU0gpB53+iOmr3FB1FWEDp2jUKXONM6oqqZSIH4DbwBhWGxDjFUTdMmYto4HLyAcoCYZVARXURzi1RTV8LwftF2lFPwaMz8uAkBdYpXm73Z7U5eAXW9vdcJg2k6QAAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    background-position: center;
}

.footer-bg_4241101_123 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 1;
    background-color: transparent;
    background-size: contain;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFqSURBVHgBhVI7UsMwEJVkT2rnBIQbhDZVOAInIG7zw5wg5AR47BnbHU5HB5wA9y7ikg6VlPT+8TZIgwiO2UJarfR23z4tZx02n8/HlmWN27Ydcc5lXddFFEXF8TtuHpbL5RSPN3CnHTmzsizdJEnkH/B6vb5GpZT9Y0g+C4JgR76gxfO8kQH8hL8F7WEYhhz7edM0txSnS9z51Bb5Ni3o6UEDQe3CpOb7PvkEyJDoFb6D/R77JaeqAL+rty6qpacoQ5M7pQkjZqKqqrG+RNWM9Zht26n2gZsKZHJ0wKTbZaqFg0EHR5iXi8XirA+shSITQkgB7s86ABZuHxhvb9hPC4UAFfqCTIE3ZnbTaA6wzdQxJZytMrpQfM++v2G/Wq1S/OcOokhUoBGlAdJACWG3h2JmPwA+wR2x00ZzfqXn3NLRPM8/JpPJC1QcotIxdWrtkWY7juM3HeRd6TE4jvp/B8nkYDCQSptf9gV/46xP4KTknwAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-position: center;
}

.footer-bg_4241101_124 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 1;
    background-color: transparent;
    background-size: contain;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABPSURBVHgBAUQAu/8BcHBwEP39/VD7+/vAmJiY4AFtbW2vAAAAUAAAAPADAwMhAgAAAEAAAAAAAAAAEPz8/DACAAAAcf///9D///+RlJSUwLJCHCPIGsmbAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: center;
}

.footer-bg_4241102_127 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 1;
    background-color: transparent;
    background-size: contain;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAbCAYAAABvCO8sAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGcSURBVHgB7VXRbYMwED0I+YYR2KDtBskG2aDtXz4SSiZosgESCPHXdIMyQdig2aBsUPoNiD4jXDnIUFKgVaU8ybJ95+PhO9+dQoBlWQ9FUWyxNGg8xGmazhXbto08z9/pdxCqNO6tTgAv6mqL8ogR0cCQEsLFN57nsTEH6Y5GJgx93z/yDUi3mGIakVD2gAaLs4xwsVwuTb5hKTMkoSaRGdPp9G29XkeKohiI4TUNCK1FNwMZdUSCszGb8ZPsB41zCMuK8HVA00x85FBtN9C9cB08cQDRM844QMLlq9VqBpsnLM0uhBQEQczXqEQsTco15kTUwe07vOJ93R6yCHZznH+l2m0bE78LXNfdN+lw4xi3D+vythh2QlX4Z1gmk8lkI7oWbo0w3Q5GCJfegczh+yzLPjDZfM8eEkhPbHq5VJIyV9/Z9CKkHxSEvoRn40L4/wmleSi2J+SWyXMJNVMXdZDrop2qqoaox16vNwAZocnak0TO8s6BzqEGsLwUbWXd5k9imFRjdCAEcRkc9K8FpkfW4WkksLqK93D/CXSEsg7HfNKBAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: center;
}

@include for-tablet {
    .footer-logo {
        bottom: -60px;
        img {
            height: 80px;
        }
    }
    .footer-badge {
        bottom: 35px;
        right: 30px;
    }
}

@include for-mobile {
    .footer-section {
        height: auto;
    }
    .footer-container {
        flex-direction: column;
    }
    .footer-left {
        width: calc(100% - 40px);
        padding: 80px 20px 20px;
    }
    .footer-left-deesc {
        margin-bottom: 40px;
    }
    .footer-right {
        width: calc(100% - 40px);
        padding: 40px 20px 20px;
    }
    .footer-right-right-heading {
        margin: 0 0 55px;
    }
    .footer-right-icons {
        margin: 60px 0;
    }
    .footer-middle {
        height: 100%;
    }
    .footer-middle-bg {
        height: 100%;
    }
    .footer-middle-dots {
        height: 100%;
        width: 100%;
        top: -120px;
        left: 0;
        right: 0;
        margin: auto;
    }
    .footer-logo {
        position: relative;
        bottom: 0;
        left: 0;
        right: 0;
        width: calc(100% - 20px);
        padding: 20px 10px 80px;
        margin: auto;
        img {
            width: calc(100% - 20px);
            height: auto;
        }
    }
    .footer-badge {
        bottom: 480px;
        right: 20px;
        img {
            width: 220px;
        }
    }
}