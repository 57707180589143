@include for-mini-desktop-1 {

}

@include for-mini-desktop-2 {
    .how-it-works-wrapper {
        height: 55vw;
    }

    .hiw-animation-container {
        transform: scale(0.75) translateX(calc(50% - 1100px));
        -webkit-transform: scale(0.75) translateX(calc(50% - 1100px));
        left: 50%;
        transform-origin: top left;
        -webkit-transform-origin: top left;
        margin: 80px auto 0;

        .intro-play-ply-btn-wrapper-2 {
            .intro-play-ply-btn-container {
                padding: 50px;
            }
        }
    }

    .features-cards {
        transform: scale(0.8) translateX(calc(50% - 1154px));
        -webkit-transform: scale(0.8) translateX(calc(50% - 1154px));
        left: 50%;
        width: 1154px;
        transform-origin: top left;
        -webkit-transform-origin: top left;
    }

    .specs-info-container-1, .specs-info-container-2 {
        transform: scale(0.8) translateX(calc(50% - 1150px));
        -webkit-transform: scale(0.8) translateX(calc(50% - 1150px));
        left: 50%;
        width: 1150px;
        transform-origin: top left;
        -webkit-transform-origin: top left;
    }

    .subscription-wrapper {
        transform: scale(0.8) translateX(calc(50% - 1150px));
        -webkit-transform: scale(0.8) translateX(calc(50% - 1150px));
        left: 50%;
        width: 1150px;
        transform-origin: top left;
        -webkit-transform-origin: top left;
        margin: auto;
    }
}

@include for-tablet {
    .how-it-works-wrapper {
        height: 60vw;
    }

    .hiw-animation-container {
        transform: scale(0.65) translateX(calc(50% - 1100px));
        -webkit-transform: scale(0.65) translateX(calc(50% - 1100px));
        left: 50%;
        transform-origin: top left;
        -webkit-transform-origin: top left;
        margin: 80px auto 0;

        .intro-play-ply-btn-wrapper-2 {
            .intro-play-ply-btn-container {
                padding: 50px;
            }
        }
    }

    .features-wrapper {
        height: 500px;
    }

    .features-cards {
        transform: scale(0.65) translateX(calc(50% - 1154px));
        -webkit-transform: scale(0.65) translateX(calc(50% - 1154px));
        left: 50%;
        width: 1154px;
        transform-origin: top left;
        -webkit-transform-origin: top left;
    }

    .specs-info-container-1 {
        height: auto;
        margin: 80px auto;

        .specs-info-1-left {
            position: relative;
            transform: translateX(calc(50% - 600px));
            -webkit-transform: translateX(calc(50% - 600px));
            left: 50%;

            .node-8747678 {
                width: 100%;
                left: 0;
                text-align: center;
            }

            .node-8747681 {
                width: 100%;
                left: -28px;
                text-align: center;
            }
        }

        .specs-info-1-right {
            position: relative;
            width: 100%;

            .node-8747675 {
                position: relative;
                width: 100%;
                height: auto;
                text-align: center;
            }

            .node-8747676 {
                position: relative;
                width: 100%;
                height: auto;
                text-align: center;
                top: 0;
                margin: 20px auto 0;
            }
        }
    }

    .specs-info-container-2 {
        height: auto;

        .specs-info-2-left {
            position: relative;
            width: 100%;

            .node-8747780 {
                position: relative;
                width: 100%;
                height: auto;
                text-align: center;
            }

            .node-8747781 {
                position: relative;
                width: 100%;
                height: auto;
                text-align: center;
                top: 0;
                margin: 20px auto 0;
            }
        }

        .specs-info-2-right {
            position: relative;
            width: 735px;
            margin: 80px auto 100px;
            transform: translateX(calc(50% - 735px));
            -webkit-transform: translateX(calc(50% - 735px));
            left: 50%;
            transform-origin: top left;
            -webkit-transform-origin: top left;
            height: 360px;

            .node-8747782 {
                right: -80px;
                left: 0;
                margin: auto;
            }
        }
    }

    .subscription-wrapper {
        height: 480px;
    }

    .subscription-container {
        position: relative;
        width: 100%;
        left: 0;
        height: 340px;
    }
    .subscription-heading {
        width: 94%;
        left: 0;
        right: 0;
        top: 80px;
        margin: auto;
    }
    .subscription-desc {
        width: 94%;
        left: 0;
        right: 0;
        top: 40%;
        margin: auto;
    }
    .subscription-btn {
        width: 94%;
        left: 0;
        right: 0;
        top: 54%;
        margin: auto;
        .subscription-btn_87410950 {
            width: 100%;
            .subscription-btn_87410951 {
                width: 100%;
            }
            .subscription-btn_87410952 {
                width: 100%;
                top: 22px;
                left: 0;
            }
        }
    }
}

@include for-mobile {
    .main-bg-right-1 {
        top: -5%;
        left: 50%;
    }
    .main-bg-left-1 {
        width: 50vw;
        height: 50vw;
        top: 40vh;
        left: -10vw;
        background: #2603fb8c;
    }

    .intro-heading {
        margin: 4vh auto;
    }
    
    .intro-badge {
        margin: 0 auto 20px;
    }

    .intro-badge img {
        height: 40px;
    }

    .intro-input-wrapper {
        height: 110px;
    }

    .intro-input-bg {
        height: 50px;
    }

    .intro-input-text-container {
        height: 50px;
        width: 94%;
        left: 3%;
    }

    .intro-input-text {
        font-size: min(max(12px, 2vw), 28px);
        line-height: min(max(12px, 2vw), 28px);
        margin: 20px 0;
        overflow: hidden;
    }

    .intro-input-action {
        top: auto;
        bottom: 10px;
        left: 0;
        right: 0;
        margin: auto;
        height: 40px;
    }

    .intro-input-action-btn-txt {
        font-size: 18px;
        line-height: 40px;
    }

    .intro-play-continer {
        margin-top: 12vh;
        padding: 0 0 50px;
        
        .intro-play-icon {
            height: 50px;
            margin: 20px auto 0;
        }
    
        .intro-play-texts {
            margin: 5px auto 15px;
            .intro-play-text {
                font-size: 14px;
            }
        }

        .intro-play-wave-check-wrapper {
            margin: 10px auto;
            transform: scale(0.75);
            -webkit-transform: scale(0.75);
        }
    
        .intro-play-ply-btn-wrapper {
            bottom: -100px;
            height: 80px;
            .intro-play-ply-btn-container {
                padding: 25px;
            }
        }
    }


    .waveform-mockup {
        background: url('../assets/waves-mobile.png') no-repeat center center;
        background-size: contain;
        width: 90%;
        height: 80px;

        &-mastered {
            background: url('../assets/waves-mobile-mastered.png') no-repeat center center;
            background-size: contain;
        }
    }

    .ss-bg {
        width: 50vw;
        height: 50vw;
        filter: blur(50px);
    }

    .ss-image-container {
        padding: 10px 10px 5px;
    }

    .how-it-works-wrapper {
        height: 80vw;
    }

    .hiw-animation-container {
        transform: scale(0.30) translateX(calc(50% - 1100px));
        -webkit-transform: scale(0.30) translateX(calc(50% - 1100px));
        left: 50%;
        transform-origin: top left;
        -webkit-transform-origin: top left;
        margin: 80px auto 0;

        .intro-play-ply-btn-wrapper-2 {
            .intro-play-ply-btn-container {
                padding: 60px;
                img {
                    width: 60px;
                    height: auto;
                    object-fit: contain;
                }
            }
        }

        .intro-play-wave-check-wrapper-2 {
            transform: scale(2.5);
            -webkit-transform: scale(2.5);
            top: 340px;
            left: 750px;
        }
    }

    .features-wrapper {
        height: auto;
    }

    .features-cards {
        height: auto;
        margin-bottom: 0;
    }

    .features-left {
        position: relative;
        width: 100%;
        .features-left-bg {
            width: 100%;
        }
        .features-left-inners_8748681, .features-left-inners_8748682 {
            width: 92%;
            left: 4%;
            text-align: center;
        }

        .features-left-inners_8748676 {
            left: 0;
            right: 0;
            margin: auto;
        }
    }

    .features-middle {
        position: relative;
        width: 100%;
        left: 0;
        margin: 30px auto;
        .features-middle-bg {
            width: 100%;
            left: 0;
        }
        .features-middle_8749175, .features-middle_8749176 {
            width: 92%;
            left: 4%;
            text-align: center;
        }

        .features-middle_8749177 {
            top: 150px;
            left: 0;
            right: 0;
            margin: auto;
        }
    }

    .features-right {
        position: relative;
        width: 100%;
        left: 0;
        .features-right-bg {
            width: 100%;
            left: 0;
        }
        .features-right_8749165, .features-right_8749166 {
            width: 92%;
            left: 4%;
            text-align: center;
        }
        .features-right_8748695 {
            left: 0;
            right: 0;
            margin: auto;
        }
    }

    .specs-wrapper {
        margin: 30px auto;
    }

    .specs-heading-0 {
        line-height: 1.2;
    }

    .specs-info-container-1 {
        height: auto;
        margin: 80px auto;

        .specs-info-1-left {
            position: relative;
            transform: scale(0.60) translateX(calc(50% - 600px));
            -webkit-transform: scale(0.60) translateX(calc(50% - 600px));
            left: 50%;
            transform-origin: top left;
            -webkit-transform-origin: top left;
            margin-bottom: -280px;

            .node-8747678 {
                width: 100%;
                left: 0;
                text-align: center;
            }

            .node-8747681 {
                width: 100%;
                left: -28px;
                text-align: center;
            }
        }

        .specs-info-1-right {
            position: relative;
            width: 100%;
            height: 380px;

            .node-8747675 {
                position: relative;
                width: 100%;
                height: auto;
                text-align: center;
            }

            .node-8747676 {
                position: relative;
                width: 100%;
                height: auto;
                text-align: center;
                top: 0;
                margin: 20px auto 0;
            }
        }
    }

    .specs-info-container-2 {
        height: auto;

        .specs-info-2-left {
            position: relative;
            width: 100%;

            .node-8747780 {
                position: relative;
                width: 100%;
                height: auto;
                text-align: center;
            }

            .node-8747781 {
                position: relative;
                width: 100%;
                height: auto;
                text-align: center;
                top: 0;
                margin: 20px auto 0;
            }
        }

        .specs-info-2-right {
            position: relative;
            width: 735px;
            margin: 80px auto 100px;
            transform: scale(0.60) translateX(calc(50% - 735px));
            -webkit-transform: scale(0.60) translateX(calc(50% - 735px));
            left: 50%;
            transform-origin: top left;
            -webkit-transform-origin: top left;
            height: 200px;

            .node-8747782 {
                right: -80px;
                left: 0;
                margin: auto;
            }
        }
    }

    .subscription-wrapper {
        height: 350px;
    }

    .subscription-container {
        position: relative;
        width: 100%;
        left: 0;
        height: 200px;
    }

    .subscription-heading {
        width: 94%;
        left: 0;
        right: 0;
        top: 60px;
        margin: auto;
    }

    .subscription-desc {
        width: 94%;
        left: 0;
        right: 0;
        top: 34%;
        margin: auto;
    }

    .subscription-btn {
        width: 84%;
        left: 0;
        right: 0;
        top: 45%;
        margin: auto;
    }

    .subscription-btn_87410950 {
        width: 100%;
        height: 40px;
    }

    .subscription-btn_87410951 {
        width: 100%;
        height: 40px;
    }

    .subscription-btn_87410952 {
        width: 100%;
        top: 8px;
        left: 0;
    }

    .subscription-btn_87410952-0 {
        font-size: 16px;
    }
}

@include new-mobile {
    .hiw-animation-container {
        transform: scale(0.35) translateX(calc(50% - 1100px));
        -webkit-transform: scale(0.35) translateX(calc(50% - 1100px));
    }

    .specs-info-container-1 .specs-info-1-left {
        position: relative;
        transform: scale(0.7) translateX(calc(50% - 600px));
        -webkit-transform: scale(0.7) translateX(calc(50% - 600px));
        margin-bottom: -200px;
    }

    .specs-info-container-1 .specs-info-1-right {
        height: 480px;
    }

    .specs-info-container-2 .specs-info-2-right {
        transform: scale(0.7) translateX(calc(50% - 735px));
        -webkit-transform: scale(0.7) translateX(calc(50% - 735px));
        height: 240px;
    }
}