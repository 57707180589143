@charset "UTF-8";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Over+the+Rainbow:wght@400&display=swap");

@import "styles/mixins.scss";
@import "styles/Navbar.scss";
@import "styles/Footer.scss";
@import "styles/HomePage.scss";
@import "styles/HomePage_mobile.scss";
@import "styles/About.scss";
@import "styles/About_mobile.scss";
@import "styles/Pricing.scss";
@import "styles/Pricing_mobile.scss";
@import "styles/Faq.scss";

html {
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

body {
  margin: 0;
  padding: 0;
  line-height: normal;
  font-family: Inter;
  background: #020202;
  width: 100vw;
}

div#root {
  width: 100vw;
  overflow: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}

.titling {
  position: relative;
  text-align: center;
  margin: 80px auto 20px;
  padding: 20px 0;
  max-width: 98%;

  @include for-mobile {
    margin: 40px auto 20px;
  }

  &-0 {
    font-size: min(max(16px, 4vw), 34px);
    font-weight: 400;
    line-height: 1;
    margin: 0 auto;
  }

  &-1 {
    font-size: min(max(24px, 6vw), 42px);
    font-weight: 700;
    line-height: 1;
    margin: 10px auto;
  }

  &-3 {
    font-size: min(max(24px, 6vw), 48px);
    font-weight: 700;
    line-height: 1;
    margin: 10px auto;
  }

  &-4 {
    font-size: min(max(16px, 4vw), 18px);
    font-weight: 200;
    line-height: 1;
    margin: 10px auto;
    color: #848484ff;
  }

  &::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 68px;
    top: -60px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;
    background: linear-gradient(180deg, rgba(132, 132, 132, 0) 0%, rgba(132, 132, 132, 0.462745098) 53%, rgba(132, 132, 132, 0.462745098) 53%, rgba(132, 132, 132, 0) 100%);

    @include for-mobile {
      height: 48px;
      top: -40px;
    }
  }
}

.regular-container {
  display: block;
  width: 96%;
  color: white;
  padding: 80px 0 140px;
  margin: auto;

  @include for-mobile {
    padding: 60px 0 80px;
  }

}

.regular-wrapper {
  display: block;
  width: 92%;
  max-width: 1150px;
  margin: auto;
}

.absoulteLoading {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  .loading-with-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 20px;
  }
}

.d-none {
  display: none !important;
}

.error-page {
  position: relative;
  height: 550px;
  margin-top: -150px;
  min-height: calc(100% - 50px);

  @keyframes ol-500-text--flicker {

      0%,
      29% {
          opacity: .2;
      }

      30%,
      34% {
          opacity: .4;
      }

      35%,
      39% {
          opacity: .1;
      }

      40%,
      49% {
          opacity: .2;
      }

      50%,
      100% {
          opacity: .1;
      }
  }

  @keyframes ol-500-text-letter--flicker {

      0%,
      9% {
          opacity: 1;
      }

      10%,
      29% {
          opacity: .5;
      }

      30%,
      34% {
          opacity: 1;
      }

      35%,
      49% {
          opacity: 0;
      }

      50%,
      100% {
          opacity: 1;
      }
  }

  @keyframes ol-500-image--flicker {

      0%,
      9% {
          opacity: .2;
      }

      10%,
      29% {
          opacity: .5;
      }

      30%,
      34% {
          opacity: .2;
      }

      35%,
      49% {
          opacity: .4;
      }

      50%,
      59% {
          opacity: .3;
      }

      60%,
      74% {
          opacity: .5;
      }

      75%,
      89% {
          opacity: .2;
      }

      90%,
      100% {
          opacity: .4;
      }
  }

  &-image {
      position: absolute;
      top: 5vmin;
      left: 0;
      right: 0;
      margin: auto;
      width: 320px;
      opacity: .2;
      animation: ol-500-image--flicker 2.5s ease infinite .2s;

      img {
          margin: auto;
          width: 100%;
          height: auto;
          object-fit: contain;
      }
  }

  &-text {
      display: flex;
      position: absolute;
      top: 300px;
      left: 0;
      right: 0;
      justify-content: center;
      align-items: center;
      font-size: 28vmin;
      font-weight: 700;
      color: darkgray;
      z-index: 1;
      animation: ol-500-text--flicker 3s ease infinite;

      span {
          &:nth-child(2) {
              animation: ol-500-text-letter--flicker 1.5s ease infinite .2s;
          }

          &:nth-child(3) {
              animation: ol-500-text-letter--flicker 2s ease infinite .1s;
          }
      }
  }
}