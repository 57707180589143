.faq-card-wrapper {
  position: relative;
  width: 96%;
  max-width: 1150px;
  margin: auto;

  .faq-card {
    display: block;
    margin: 10px auto;
    padding: 30px;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: rgba(217, 217, 217, 0.05);
    backdrop-filter: blur(5px);
    
    .card-header {
      display: block;
      cursor: pointer;
    }

    .card-description {
      margin-top: 30px;
      opacity: .6;
    }
  }
}